<script>
export default {
  name: "Foot"
}

</script>

<template>
  <div class="footer">
    <div class="container_wrap">
      <div class="footer_top flex flex_pack_justify">
        <div class=" f_item">
          <span class="iconfont iconmap"></span>
          <span>{{$t("msg.foot.address")}}</span>
        </div>
        <div class=" f_item">
          <span class="iconfont iconlink"></span>
          <span>www.ck-yaokey.com</span>
        </div>
<!--        <div class=" f_item">-->
<!--          <span class="iconfont iconcall"></span>-->
<!--          <span>0755-28687293</span>-->
<!--        </div>-->
      </div>
      <div class="footer_bottom clearfix">
        <div class="copyright fl">Copyright © 2024 yaokey. All Rights Reserved. {{$t("msg.foot.copyright")}}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.footer {
  width: 100%;
  background-color: #3d3d3d;
  color: #ffffff;
  position: relative;
  padding-top: 80px;
}
@media(max-width: 767px){.footer_logo img{width:60px;height:60px;}
  .footer_top{width:100%;display:block;}
  .f_item{width:100%;margin-bottom:10px;text-align:left;font-size:14px;}
  .footer_bottom{text-align:center;padding-top:10px;}
  .copyright{float:none;font-size:12px;}
  .beian{float:none !important;padding-right:0 !important}
}
</style>